.section {
  $desktop: (
          1: 4rem,
          2: 8rem,
          3: 12rem,
          4: 16rem,
          5: 20rem
  );


  $mobile: (
          1: 2rem,
          2: 4rem,
          3: 6rem,
          4: 8rem,
          5: 10rem
  );

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    //margin-bottom: 0;
  }

  &--mt {
    @for $i from 1 through 5 {
      &-#{$i} {
        margin-top: map-get($mobile, $i);

        @include media-breakpoint-up(lg) {
          margin-top: map-get($desktop, $i);
        }
      }
    }
  }

  &--mb {
    @for $i from 1 through 5 {
      &-#{$i} {
        margin-bottom: map-get($mobile, $i);

        @include media-breakpoint-up(lg) {
          margin-bottom: map-get($desktop, $i);
        }
      }
    }
  }
}