.simple-circles {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 2rem 0;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border: solid 1px currentColor;
    border-radius: 50%;
    margin-bottom: 2rem;

    @include media-breakpoint-up(sm) {
      margin: 0 2rem 0 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  &__text {
    &-big {
      font-size: $h1-font-size;
      line-height: $h1-font-size;
    }

    &-small {

    }
  }
}