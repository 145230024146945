@use 'sass:list';

@mixin create-icons() {
  $icons: [
      (
        name: 'arrow-right',
        file: 'arrow-right',
        size: [20, 20]
      ),
      (
        name: 'arrow-left',
        file: 'arrow-right',
        size: [20, 20],
        rotate: 180
      ),
      (
        name: 'star',
        file: 'star',
        size: [30, 30]
      )
  ];

  $temp: '';

  @each $icon in $icons {
    $temp: $temp + '.icon-' + map-get($icon, 'name') + ', ';
  }

  #{$temp} {
    display: inline-block;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }

  @each $icon in $icons {
    .icon-#{map-get($icon, 'name')} {
      width: list.nth(map-get($icon, 'size'), 1)+px;
      height: list.nth(map-get($icon, 'size'), 2)+px;

      &:before {
        mask-image: url("../../assets/private/img/icons/#{map-get($icon, 'file')}.svg");
        mask-size: contain;
        background: currentColor;
        @if(map-get($icon, 'reverse')) {
          transform: rotate(180deg);
        }
      }
    }
  }
}

@include create-icons();