.reviews-slider {
  width: 100%;

  &__title {
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;

    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
    }
  }

  &__wrapper {
    padding: 0;
    margin: 0;
  }

  &__slider {
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .slide {
      &__rating {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;

        &-item {
          color: $primary;
          margin-right: .5rem;
        }
      }

      &__title {
      }

      &__text {
        max-width: 560px;
      }

      &__author {
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    &-item {
      padding: .25rem;
      margin-right: .5rem;

      &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        border: solid 5px $grey-200;
        border-radius: 50%;
        cursor: pointer;
        transition: border-color $transition-base, background-color $transition-base;
      }

      &:hover:before {
        background-color: $grey-200;
      }

      &.is-active:before {
        border-color: $grey-400;
        background-color: $grey-400;
      }
    }
  }
}