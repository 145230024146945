.article-list-item {
  &__container {
    display: flex;
    flex-direction: column;
    color: inherit;

    &:hover {
      color: inherit;

      .article-list-item {
        &__icon {
          margin-left: 1rem;
        }
      }
    }

    @include media-breakpoint-up(xs) {
      flex-direction: row;
    }

    @include media-breakpoint-down(xl) {
      max-width: 100%;
      padding: 0;
    }
  }

  &__image {
    @include media-breakpoint-up(xs) {
      width: 50%;
    }
  }

  &__content {
    padding: 2rem;

    @include media-breakpoint-up(xs) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
    }

    @include media-breakpoint-up(sm) {
      padding: clamp(2rem, -1rem + 8.333vw, 3rem);
    }

    @include media-breakpoint-up(md) {
      padding: clamp(3rem, -1.556rem + 7.407vw, 4rem);
    }

    @include media-breakpoint-up(xl) {
      padding-top: 8vw;
      padding-bottom: 5vw;
    }
  }

  &__title {
    font-size: clamp($h2-font-size-mobile, 4.5vw, $h2-font-size);

    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
      font-size: clamp(1.25rem, -0.083rem + 2.778vw, 2rem);
    }
  }

  &__description {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      font-size: clamp(1rem, 0.139rem + 1.389vw, 1.25rem);
    }
  }

  &__icon {
    transition: margin $transition-base, transform $transition-base;
    width: 28px;
    height: 28px;

    @include media-breakpoint-up(xs) {
      margin-top: auto;
      transform: rotate(180deg);
    }
  }

  &--reverse {
    .article-list-item {
      &__container {
        &:hover {
          .article-list-item {
            &__icon {
              @include media-breakpoint-up(xs) {
                margin-right: 1rem;
              }
            }
          }
        }
      }
      &__image {
        @include media-breakpoint-up(xs) {
          order: 2;
        }
      }

      &__content {
        @include media-breakpoint-up(xs) {
          order: 1;
        }
      }

      &__icon {
        @include media-breakpoint-up(xs) {
          align-self: flex-end;
          transform: rotate(0deg);
        }
      }
    }
  }
}