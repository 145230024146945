@import "grid";
@import "transitions";
@import "colors";
@import "typography";

$rem-value: 16px;

$header-height-xs: 112px;
$header-height-md: 72px;
$header-height: 160px;

$border-radius: 4px;