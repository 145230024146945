.woocommerce-notice {
  max-width: 800px;
  padding: .5rem 1rem;
  border: solid 1px $grey-400;
  border-radius: .25rem;
  margin: 1rem auto;

  &--success {
    border-color: $green;
  }
}