.wc-price {
  font-size: $font-size-lg;
  font-weight: $font-weight-semi-bold;
  color: $primary;

  &__text-before {
    margin-right: 1ch;
  }

  &__amount {}

  &__currency {}

  &__regular {
    margin-left: 1ch;
    color: $black;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
  }
}