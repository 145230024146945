.button {
  display: inline-block;
  padding: 1rem 2.5rem;
  border: solid 2px $primary;
  margin: 0;
  background: $primary;
  color: $white;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  text-align: center;
  border-radius: 999px;
  transition: background-color $transition-base, color $transition-base;

  &:hover {
    background: transparent;
    color: $primary;
  }

  &--sm {
    padding: .5rem 2rem;
  }
}