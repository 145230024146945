// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xxs: 0,
        xs: 424px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1280px,
        xxl: 1900px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        xl: 1872px
) !default;

$container-small-max-widths: (
        lg: 1200px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-row-columns: 12 !default;

$grid-breakpoint-change: lg;

$grid-gutter-width: 12px !default;
$grid-gutter-width-desktop: 24px !default;

$grid-container-gutter-width: 36px;
$grid-container-gutter-width-desktop: 36px;
