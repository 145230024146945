.ingredients-slider {
  width: 100%;

  &__title {
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;

    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
    }
  }

  &__slider {
  }

  &__wrapper {
    padding: 0;
    margin: 0;
  }

  &__slide {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      flex-direction: column;
    }

    &-img {
      flex: 0 0 auto;
      width: 33.33%;

      @include media-breakpoint-up(md) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }

    &-content {
      padding-left: 1rem;

      @include media-breakpoint-up(md) {
        text-align: center;
      }
    }

    &-title {
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        font-weight: $font-weight-normal;
      }
    }

    &-description {
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    &-item {
      padding: .25rem;
      margin-right: .5rem;

      &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        border: solid 5px $grey-200;
        border-radius: 50%;
        cursor: pointer;
        transition: border-color $transition-base, background-color $transition-base;
      }

      &:hover:before {
        background-color: $grey-200;
      }

      &.is-active:before {
        border-color: $grey-400;
        background-color: $grey-400;
      }
    }
  }
}
