.woocommerce-order {

  > p {
    font-size: $font-size-lg;
    font-weight: $font-weight-semi-bold;
  }

  .woocommerce-order-overview,
  .wc-bacs-bank-details {
    list-style: none;
    display: flex;
    flex-direction: column;
    //width: fit-content;
    padding: 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin: 0 -1rem;
    }

    > li {
      padding: 1rem 0;
      border-bottom: dashed 1px $grey-400;

      @include media-breakpoint-up(md) {
        padding: .75rem 1rem;
        border-right: dashed 1px $grey-400;
        border-bottom: none;
      }

      &:last-child {
        border: none;
      }
    }
  }

  .woocommerce-order-overview {
    margin: 3rem auto;
  }

  .woocommerce-bacs-bank-details .wc-bacs-bank-details-heading,
  .woocommerce-order-details .woocommerce-order-details__title {
    font-size: $h2-font-size;

    @include media-breakpoint-up($headings-breakpoint) {
      font-size: $h2-font-size;
    }
  }

  .woocommerce-bacs-bank-details {
    @include media-breakpoint-up(md) {
      margin-bottom: 1rem;
    }
    .wc-bacs-bank-details-account-name {
      font-size: $font-size-base;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        margin-bottom: .5rem;
      }

      @include media-breakpoint-up($headings-breakpoint) {
        font-size: $h3-font-size;
      }
    }
  }

  .woocommerce-order-details {
    margin: 3rem 0;
    
    @include media-breakpoint-up(md) {
      margin: 4rem 0;
    }
  }

  .woocommerce-columns {
    display: flex;
    flex-direction: column;
    margin: 0 -.25rem;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    .col-1,
    .col-2 {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 .25rem;

      @include media-breakpoint-up(sm) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    address {
      display: block;
      padding: 1rem;
      border: 1px solid $grey-200;
      border-radius: .5rem;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}