ul, ol {
  &.list-checkmarks {
    list-style: none;
    padding: 0;

    li {
      &:before {
        content: '\2713';
        margin-right: 1ch;
      }
    }
  }
}