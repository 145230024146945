.shop_table {
  border: 1px solid $grey-200;
  margin: 0 -1px 1rem 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: .5rem;

  tr {
    width: 100%;
  }

  th, td {
    padding: 1rem .5rem;
  }

  th {
    font-weight: $font-weight-semi-bold;
  }

  td {
    border-top: solid 1px $grey-200;
  }

  thead th {
    padding: 1rem .5rem;
  }

  tfoot {
    th {
      border-top: solid 1px $grey-200;
    }

    td {
      font-weight: $font-weight-semi-bold;
    }
  }
}

.shop_table_responsive {
  @include media-breakpoint-down(sm) {
    display: flex;
    position: relative;

    thead,
    tbody {
      flex: 1 1 auto;
    }

    thead tr,
    tbody tr {
      display: flex;
      flex-direction: column;
    }

    th, td {
      &:first-child,
      &:last-child {
        border-top: none;
      }

      &:last-child{
        min-height: 5rem;
      }
    }

    th {
      text-wrap: nowrap;
      border-top: solid 1px $grey-200;
    }

    td {
      &:last-child {
        &:before {
          content: '\00a0';
        }
      }
    }

    .button {
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translate(-50%);
      width: calc(100vw - 6rem);
    }
  }
}