@import "article-list-item";

.articles-list {
  &__container {
  }

  &__title {
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;

    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}