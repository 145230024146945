.simple-article {
  margin: 4rem 0;

  @include media-breakpoint-up(lg) {
    margin: 8rem 0;
  }

  &__inner {
    max-width: 800px;
    margin: 0 auto;
  }
}