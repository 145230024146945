.woocommerce-checkout.checkout {
  margin-top: 4rem;
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: center;
  }

  .checkout {
    &__order,
    &__payment {

    }

    &__order {
      @include media-breakpoint-up(lg) {
        flex: 0 0 auto;
        width: 50%;
        padding-top: 61px;
      }
      @include media-breakpoint-up(lg) {
      }
    }

    &__payment {
      display: flex;
      justify-content: center;
    }

    &__address {
      display: flex;
      flex-direction: column;
    }
  }
}

.woocommerce-checkout-review-order {
  .wc-price {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;

    &__text-before {
      display: none;
    }

    &__disclaimer {
      display: none;
    }

    &--sale {
      .wc-price__regular {
        //display: none;
      }
    }
  }

  .cart-after-total-text {
    td {
      font-weight: $font-weight-normal;
    }
  }
}

.woocommerce-checkout-fields,
.woocommerce-additional-fields {
  margin-bottom: 2rem;

  &__inner {
    border: solid 1px $grey-400;
    border-radius: 1rem;
  }

  .form-row {
    position: relative;
    margin-bottom: 0;
    border-bottom: solid 1px $grey-400;

    &:last-child {
      border-bottom: none;
    }

    > label {
      position: absolute;
      left: 1rem;
      top: 1.5rem;
      margin: 0;
      color: $grey-400;
      transition: top $transition-base, font-size $transition-base;
    }

    .required {
      text-decoration: none;
    }

    .woocommerce-input-wrapper > strong,
    .input-text {
      padding: 2rem 1rem 1rem;
      background: transparent;
      transition: padding $transition-base;
    }

    .woocommerce-input-wrapper {
      display: flex;

      > strong {
        display: block;
      }
    }

    .input-text {
      width: 100%;
      border: none;

      &:focus,
      &:focus-visible {
        outline: none;
        //background: $grey-200;
      }
    }

    &.is-active {
      > label {
        top: .5rem;
        font-size: $font-size-sm;
      }

      .woocommerce-input-wrapper > strong,
      .input-text {
        padding: 2rem 1rem 1rem;
      }
    }
  }

  .checkbox {
    position: relative;
    display: flex;
    align-items: center;

    &:before {
      content: '\2713';
      position: absolute;
      left: 4px;
      top: 50%;
      font-size: 14px;
      color: $white;
      transform: translateY(-30%);
    }

    .input-checkbox {
      appearance: none;
      width: 1.25rem;
      height: 1.25rem;
      border: solid 1px $primary;
      border-radius: .25rem;
      margin-right: 1rem;
      transition: background-color $transition-base;

      &:checked {
        background: $primary;
      }
    }
  }

  &.is-hidden {
    .woocommerce-checkout-fields__inner {
      display: none;
    }
  }
}



#streamlineForm input[type="submit"] {
  display: none;
}

#streamlineFrame {
  width: 400px;
  height: 1100px;
  border: none;
}