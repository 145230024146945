body.single-product {
  .product {
    .woocommerce-container {
      display: flex;
      flex-direction: column;
      margin-top: 8rem;
      margin-bottom: 8rem;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: center;
      }
    }

    .woocommerce-product-gallery {
      width: 100%;
      max-width: 565px;
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        flex: 0 1 auto;
        width: 50%;
        margin: 0 2.125rem 0 0;
      }

      @include media-breakpoint-up(lg) {
        margin: 0 4.5rem 0 0;
      }

      &__item {
        position: relative;
      }

    }

    .sale-bubble {
      z-index: 10;
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: clamp(80px, 25vw, 180px);
      border-radius: 50%;
      background: $white;
      color: $primary;

      @include media-breakpoint-up(sm) {
        top: 2rem;
        right: 2rem;
      }

      @include media-breakpoint-up(md) {
        top: 1rem;
        right: 1rem;
        width: clamp(80px, 10vw, 128px);
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 100%;
      }

      &__inner {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        inset: 0;
      }

      &__label,
      &__price {
        display: block;
        font-weight: $font-weight-semi-bold;
      }

      &__label {
        font-size: clamp(24px, 7.5vw, 58px);
        line-height: 1;

        @include media-breakpoint-up(md) {
          font-size: clamp(24px, 3.125vw, 40px);
        }
      }

      &__price {
        font-size: clamp(16px, 5vw, 32px);

        @include media-breakpoint-up(md) {
          font-size: clamp(16px, 2vw, 26px);

        }

        .wc-price {
          text-align: center;
          font-size: inherit;

          &__text-before {
            display: block;
            font-size: clamp(8px, 2.5vw, 16px);
            line-height: 1;

            @include media-breakpoint-up(md) {
              font-size: clamp(8px, 1vw, 12px);
            }
          }

          &__regular {
            display: none;
          }
        }
      }
    }

    .summary {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 1 auto;
        width: 50%;

        max-width: 400px;
      }

      .product_title {
        margin-bottom: 1rem;
        color: $primary;

        @include media-breakpoint-up(lg) {
          margin-bottom: 2rem;
        }
      }

      .woocommerce-product-details__short-description {
        font-size: $font-size-lg;

        > *:last-child {
          margin-bottom: 0;
        }
      }

      .price {
        margin: 1rem 0;
        text-align: center;

        @include media-breakpoint-up(lg) {
          margin-top: auto;
          margin-bottom: 2rem;
        }

        .wc-price {
          font-size: $h3-font-size-mobile;

          @include media-breakpoint-up($headings-breakpoint) {
            font-size: $h3-font-size;
          }
        }
      }

      .cart {
        text-align: center;
        .single_add_to_cart_button {
          width: 100%;

          @include media-breakpoint-up(md) {
            max-width: 280px;
          }
        }
      }

      .buy_badge {
        align-self: center;
        height: 2rem;
        width: auto;
        margin-top: 1rem;
      }

      .disclaimer {
        margin-top: 1rem;
        text-align: center;
        font-size: $font-size-sm;
      }
    }

    .reviews-slider {
      margin-bottom: 8rem;
    }

    .ingredients-slider {
      margin-bottom: 8rem;
    }

  }
}