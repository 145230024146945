.product-card {
  display: flex;
  flex-direction: column;

  &__image {
    position: relative;
    display: block;
    margin: 0 0 1.5rem;
    max-width: 100%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &-img{
      max-width: 100%;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &__bubble {
    z-index: 10;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: clamp(80px, 25vw, 180px);
    border-radius: 50%;
    background: $white;
    color: $primary;

    @include media-breakpoint-up(sm) {
      top: 2rem;
      right: 2rem;
    }

    @include media-breakpoint-up(md) {
      top: 1rem;
      right: 1rem;
      width: clamp(80px, 10vw, 128px);
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 100%;
    }

    &-inner {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      inset: 0;
    }

    &-label,
    &-price {
      display: block;
      font-weight: $font-weight-semi-bold;
    }

    &-label {
      font-size: clamp(24px, 7.5vw, 58px);
      line-height: 1;

      @include media-breakpoint-up(md) {
        font-size: clamp(24px, 3.125vw, 40px);
      }
    }

    &-price {
      font-size: clamp(16px, 5vw, 32px);

      @include media-breakpoint-up(md) {
        font-size: clamp(16px, 2vw, 26px);

      }

      .wc-price {
        text-align: center;
        font-size: inherit;

        &__text-before,
        &__regular {
          display: none;
        }
      }
    }
  }

  &__title {
    margin: 0 0 1.5rem;
    color: $primary;
  }

  &__description {
    margin: 0 0 1.5rem;
    line-height: 1.5;
  }

  &__price {
    align-self: center;
    margin: 0 0 1.5rem;
  }

  &__link {
    align-self: center;
  }
}