.contact {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    margin: 0 -1rem;
  }

  &__content,
  &__form {
    @include media-breakpoint-up(lg) {
      width: calc(50% - 2rem);
      flex: 0 0 auto;
      margin: 0 1rem;
    }
  }

  &__content {
    font-size: $font-size-lg;
    @include media-breakpoint-down(md) {
      margin-bottom: 2rem;
    }
  }

  &__form {

  }
}

.wpcf7 {
  width: 100%;

  &-form {
    display: flex;
    flex-direction: column;
  }

  &-form-control-wrap {
    width: 100%;
    margin-bottom: 1rem;
  }

  select,
  textarea,
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="submit"] {
    width: 100%;
    padding: 1rem;
    border-radius: 29px;
    border: solid 1px $grey-400;
    font-size: $font-size-lg;
  }

  select {
    background-color: transparent;
  }

  input[type="submit"] {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    font-size: $font-size-lg;
    font-weight: $font-weight-semi-bold;
  }
}