.single-article {
  &__container {
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(lg) {
      margin-top: -12vw;
      padding: 0 4rem;
    }
  }

  &__content {
    padding-top: clamp(2rem, -4.889rem + 11.111vw, 4rem);
    padding-bottom: 4rem;


    @include media-breakpoint-up(lg) {
      background-color: $white;
    }

    &-inner {
      max-width: 800px;
      margin: 0 auto;
    }

    .wysiwyg-content {
      font-size: $font-size-lg;
    }

    .responsive-image {
      margin: 2rem 0;
    }
  }

  &__header {
    margin-bottom: 2rem;
    text-align: center;

    &:after {
      content: '';
      display: block;
      width: 40%;
      height: 3px;
      margin: 3rem auto 4rem;
      background-color: $grey-400;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__excerpt {
  }
}