.header {
  height: $header-height-xs;

  @include media-breakpoint-up(md) {
    height: $header-height-md;
  }

  @include media-breakpoint-up(lg) {
    height: $header-height;
  }

  &__wrapper {
    z-index: 100;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height-xs;
    background: $white;

    @include media-breakpoint-up(md) {
      height: $header-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $header-height;
    }


    //body.admin-bar & {
    //  top: var(--wp-admin--admin-bar--height);
    //}
  }

  &__container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 100%;

    @include media-breakpoint-up(lg) {
      padding-top: 2rem;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;

    &:after {
      content: '';
      pointer-events: none;
      position: fixed;
      opacity: 0;
      inset: 0;
      background: rgba(0, 0, 0, 0.3);
      transition: opacity $transition-base;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: content-box;
    height: 18px;
    width: 26px;
    padding: 1rem;
    border: none;
    margin: 0 0 0 -1rem;
    background: transparent;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    & > span {
      width: 100%;
      height: 2px;
      background: $black;
    }
  }

  &__logo {
    width: calc(100% - 42px);
    margin: 0 auto;
  }

  &__text {
    display: block;
    width: 100%;
    margin-top: .75rem;
    font-size: $font-size-xs;
    text-align: center;
    color: $grey-600;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 19px;
      right: 0;
      width: auto;
      margin-top: 0;
      transform: translateY(-50%);
    }
  }

  &__nav {
    z-index: 10;
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 400px;
    height: 100%;
    padding: 1.5rem;
    box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.2);
    background: $white;
    transform: translate(-100%);
    transition: transform $transition-base;

    @include media-breakpoint-up(lg) {
      position: static;
      width: 100%;
      height: auto;
      padding: 0;
      margin: 2rem 0 0;
      box-shadow: none;
      background: transparent;
      transform: translate(0);
    }

    body.admin-bar & {
      top: var(--wp-admin--admin-bar--height);
    }


    .nav {
      &__btn {
        box-sizing: content-box;
        position: relative;
        width: 2rem;
        height: 2rem;
        padding: .5rem;
        border: 0;
        background: transparent;

        @include media-breakpoint-up(lg) {
          display: none;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 2rem;
          height: 2px;
          background: $black;
          transform-origin: 50% 50%;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }

      &__list {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        margin: 2.5rem 0 0;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
          margin: 0;
        }
      }

      &__item {
        margin: .5rem 0 0;

        @include media-breakpoint-up(lg) {
          margin: 0 1rem 0 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &__link {
        display: inline-block;
        padding: .5rem;
        font-size: $h4-font-size;
        color: inherit;

        @include media-breakpoint-up(lg) {
          font-weight: $font-weight-semi-bold;
        }
      }
    }
  }

  &.is-active {
    .header {
      &__inner:after {
        pointer-events: all;
        opacity: 1;
      }

      &__nav {
        transform: translate(0%);

        .nav {


          &__list {

          }

          &__item {

          }

          &__link {

          }
        }
      }
    }
  }
}