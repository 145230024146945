.hero {
  display: flex;
  position: relative;
  width: 100%;


  @include media-breakpoint-up(md) {
    max-height: unset;
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr auto auto auto 1fr;
    grid-auto-rows: auto;
    justify-items: center;
    align-content: start;
    width: 100%;
    color: $white;
  }

  &__bubble {
    z-index: 10;
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 90px;
    border-radius: 50%;
    background: $white;
    color: $primary;


    @include media-breakpoint-up(md) {
      width: 180px;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 100%;
    }

    &-inner {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      inset: 0;
    }

    &-label,
    &-price {
      display: block;
      font-weight: $font-weight-bold;
    }

    &-label {
      font-size: $font-size-sm;

      @include media-breakpoint-up(md) {
        font-size: $font-size-lg;
      }
    }

    &-price {
      font-size: $h2-font-size-mobile;

      @include media-breakpoint-up(md) {
        font-size: 44px;
      }

      .wc-price {
        font-size: inherit;

        &__text-before,
        &__regular{
          display: none;
        }
      }
    }
  }

  &__content {
    z-index: 2;
    grid-column: 1 / -1;
    grid-row: 3;
    text-align: center;
  }

  &__title {
    margin: 0;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(md) {
      font-size: $h1-font-size;
    }
  }

  &__button {
    margin-top: 2rem;
  }

  &__disclaimer {
    z-index: 2;
    grid-column: 1 / -1;
    grid-row: 5;
    align-self: end;
    width: 100%;
    padding: 0 1.5rem 1.5rem;
    text-align: center;
  }

  &__bg {
    overflow: hidden;
    grid-column: 1 / -1;
    grid-row: 1 / 6;
    width: 100%;
    height: 100%;
    line-height: 0;
  }

  &__picture-img {
    @include media-breakpoint-down(xxs) {
      max-width: unset;
    }
    @include media-breakpoint-up(xxl) {
      width: 100%;
    }
  }

  &__video {
    &-mobile,
    &-desktop {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-mobile {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &-desktop {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  &--video {
    max-height: 60vh;

    .hero {
      &__inner {

      }

      &__bg {
        grid-column: 1 / -1;
        grid-row: 1 / 6;
        width: 100%;
        height: 100%;
        line-height: 0;
      }
    }
  }
}