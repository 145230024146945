@import "product_card";

.products-cards {
  &__container {
  }

  &__content {
    margin: 0 0 2rem;

    h1 {
      color: $primary
    }
  }

  &__items {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__item {
    flex: 0 1 auto;
    min-width: 0;
    margin-bottom: 4rem;

    @include media-breakpoint-up(md) {
      margin: 0 .75rem 0 0;

      &:last-child {
        margin-right: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-right: 2.25rem;
    }

    &-inner {
    }
  }

  &__disclaimer {
    margin-top: 2rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
    }
  }
}