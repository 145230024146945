.simple-table {
  border: 1px solid $grey-300;
  margin: 0 -1px 1rem 0;
  text-align: left;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;

  tr {
    border: none;
  }

  th, td {
    border:none;
    border-top: solid 1px $grey-300;
  }

  th {
    padding: .75rem .25rem;
    font-weight: $font-weight-semi-bold;
    @include media-breakpoint-up(xs) {
      padding: .75rem .5rem;
    }

    @include media-breakpoint-up(sm) {
      padding: 1rem 1rem;
    }
  }

  td {
    padding: .5rem .25rem;

    @include media-breakpoint-up(xs) {
      padding: .5rem .5rem;
    }

    @include media-breakpoint-up(sm) {
      padding: 1rem 1rem;
    }
  }

  caption {
    margin-top: .5rem;
    text-align: center;
  }
}