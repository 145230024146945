.footer {
  border-top: solid 1px $grey-200;
  //box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.2);

  &__container {
  }

  &__inner {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 0 6rem;
    }
  }

  &__nav {
    @include media-breakpoint-up(md) {
      order: 3
    }
    .nav {
      &__list {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 0;

        @include media-breakpoint-up(md) {
          align-items: flex-start;
        }
      }

      &__item {
        margin-top: .25rem;
      }

      &__link {
        padding: .5rem;
        font-size: $font-size-sm;
        color: inherit;
      }
    }
  }

  &__socials {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 2.5rem 0 3.5rem;

    @include media-breakpoint-up(md) {
      order: 1;
      margin: 0;
    }

    .socials {
      &__item {
        margin: 0 .5rem;
      }

      &__link {
        box-sizing: content-box;
        display: block;
        height: 32px;
        padding: .5rem;

        & > svg {
          //display: block;
          height: 100%;
          width: auto;
        }
      }
    }
  }

  &__copyright {
    text-align: center;
    font-size: $font-size-sm;

    @include media-breakpoint-up(md) {
      order: 2;
    }
  }
}