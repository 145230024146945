.superwrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  body.admin-bar & {
    min-height: calc(100vh - var(--wp-admin--admin-bar--height));
  }

  .header,
  .footer {
    flex: 0 0 auto;
  }

  .footer {
    margin-top: auto;
  }

  &__main {
    flex: 1 1 auto;
  }

  &--centered {
    .superwrapper {
      &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}