.responsive-picture {
  position: relative;
  display: block;
  max-width: 100%;

  &__img {
    top: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimizeQuality;
  }

  &--cover {
    padding-top: calc(var(--height) / var(--width) * 100%);
    height: 0;

    .responsive-image__img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}