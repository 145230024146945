$white: #fff;
$black: #000;

$orange-red: #f9681c;

$primary: $orange-red;

$red-error: #BF2012;
$orange: #BE834A;
$green: #69875C;


$grey-100: #FAFAFA;
$grey-200: #EBEBEB;
$grey-300: #D5D5D5;
$grey-400: #707070;
$grey-600: #414042;

$body-bg: $white;
$body-color: $grey-600;
